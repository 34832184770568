import React from "react";
import { ContactPageQuery, SanityContactPage } from "../../graphql-types";
import { Form } from "../components/Form/Form";
import { ContactImage } from "../components/Image/ContactImage";
import { Layout } from "../components/Layout/Layout";
import { PageTitle } from "../components/PageTitle/PageTitle";
import { SEO } from "../components/Seo";
import { graphql } from "gatsby";
import * as styles from "../styles/pages/contact-us.module.scss";
import { usePageContext } from "../components/Context/pageContext";

interface Props {
  data: ContactPageQuery;
}

const Contact: React.FC<Props> = ({ data }) => {
  const { page } = data;
  const { lang } = usePageContext();

  return (
    <Layout>
      <SEO rawMeta={page?._rawMetadata as string} />
      <div className={styles.page}>
        <PageTitle content={page?.content?._rawTitle[lang] as string} />
        <div className={styles.contact}>
          <Form page={page as SanityContactPage} />
          <ContactImage page={page as SanityContactPage} />
        </div>
      </div>
    </Layout>
  );
};

export default Contact;

export const QUERY = graphql`
  query ContactPage {
    page: sanityContactPage {
      content {
        _rawTitle
        image {
          asset {
            gatsbyImageData
          }
        }
        _rawEmailPlaceholder
        _rawMessagePlaceholder
        _rawSend
        _rawCta
        _rawThanksMessage
        _rawNewsletterLabel
      }
      _rawMetadata(resolveReferences: { maxDepth: 3 })
    }
  }
`;
