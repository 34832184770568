import React, { useState } from "react";
import { CheckBox } from "../Checkbox/CheckBox";
import { usePageContext } from "../Context/pageContext";
import { SanityContactPage } from "../../../graphql-types";
import axios from "axios";
import addToMailchimp from "gatsby-plugin-mailchimp";
import * as styles from "./form.module.scss";

interface Props {
  page: SanityContactPage;
}

export const Form: React.FC<Props> = ({ page }) => {
  const { lang } = usePageContext();
  const [checked, setChecked] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    if (checked) {
      const email = data.get("email");
      addToMailchimp(email);
    }
    setSubmitting(true);
    axios({
      method: "POST",
      url: "https://getform.io/f/b4d9008a-76c2-444d-84a4-dec074a41c3e",
      data,
    })
      .then(() => {
        setSubmitting(false);
        setSent(true);
        form.reset();
      })
      .catch((r: any) => {
        setSubmitting(false);
        setSent(true);
        setError(r?.response?.data?.error);
      });
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleOnSubmit}
      method="POST"
      target="_blank"
    >
      <div className={styles.postionTitleForm}>
        {page?.content?._rawCta?.[lang] as string}
      </div>
      <label htmlFor="email">{}</label>
      <input
        id="email"
        type="text"
        required={true}
        name="email"
        formNoValidate
        autoComplete="off"
        placeholder={page?.content?._rawEmailPlaceholder?.[lang] as string}
        className={styles.inputEmail}
      />
      <div className={styles.checkBox}>
        <CheckBox
          checked={checked}
          onChange={(val: boolean) => setChecked(val)}
          label={page?.content?._rawNewsletterLabel?.[lang]}
        />
      </div>
      <textarea
        id="message"
        name="message"
        autoComplete="off"
        autoCorrect="on"
        placeholder={page?.content?._rawMessagePlaceholder?.[lang] as string}
        rows={5}
        className={styles.textareaMessage}
      />
      <label htmlFor="message" className={styles.textBox}></label>
      {sent === true ? (
        <div>{page?.content?._rawThanksMessage?.[lang] as string}</div>
      ) : (
        <div className={styles.positionButton}>
          <button
            className={`${styles.button} ${submitting && styles.deactivated}`}
            type="submit"
          >
            {page?.content?._rawSend?.[lang] as string}
          </button>
        </div>
      )}
    </form>
  );
};
