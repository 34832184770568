import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { SanityContactPage } from "../../../graphql-types";
import * as styles from "./contactImage.module.scss";

interface Props {
  page: SanityContactPage;
}

export const ContactImage: React.FC<Props> = ({ page }) => {
  return (
    <div className={styles.position}>
      <GatsbyImage
        image={page?.content?.image?.asset?.gatsbyImageData}
        alt={"concave"}
      />
    </div>
  );
};
