import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import * as styles from "./checkbox.module.scss";

interface Props {
  checked: boolean;
  onChange: (value: boolean) => void;
  label: string;
}

export const CheckBox: React.FC<Props> = ({ checked, onChange, label }) => {
  return (
    <div className={styles.subscribeNewsletter}>
      <div className={styles.box} onClick={() => onChange(!checked)}>
        <div className={checked ? styles.element : styles.unChecked}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  );
};
